<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-3/3">
          <div class="vx-card p-6 mb-6">
            <ItenaryForm></ItenaryForm>
          </div>
            <!-- Save & Reset Button -->
          <div class="vx-card p-4 mb-4">
              <div class="flex flex-wrap items-center justify-end">
                <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
              </div>
          </div>
        </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import ItenaryForm from './common/ItenaryForm';
import { mapGetters, mapActions } from 'vuex'
import loaderMixin from '../../../../mixins/loaderMixin';

export default {
  components: {
    vSelect,
    ItenaryForm
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        itenaryForm: 'main_package/getItenaryFormObj'
    }),
    getPackageId() {
        return this.$route.params.packageId;
    },
    getItenaryId(){
        return this.$route.params.itenaryId;
    }
  },
  created() {
      this.openLoading();
     this.fetchAndSetPackageItenaryById({packageId:this.getPackageId, itenaryId:this.getItenaryId}).then(res => {this.closeLoading()}).catch((r) => this.closeLoading());
  },
  methods: {
    ...mapActions('main_package', [
        'updatePackageItenaryAction',
        'fetchAndSetPackageItenaryById'
    ]),
    submitForm() {
      this.openLoading();
      this.updatePackageItenaryAction({
            packageId: this.getPackageId,
            itenaryId: this.getItenaryId,
            data: this.itenaryForm
        })
        .then(response => {
           if(response.status === 202) {
             this.closeLoading()
            this.$vs.notify({ 
                title: 'Package Itenary Updated',
                text: 'Package Itenary Updated successfully.',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push("/packages/" + this.getPackageId + "/view");
           }
        }).catch((r) => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
